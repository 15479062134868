import { Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { CreditEvaluationIncomeSource } from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { simpleNumberFormatter } from "components/Table/formatters/simpleNumberFormatter";

interface Props {
  data: CreditEvaluationIncomeSource[];
  status: QueryStatus;
}
const IncomesPaystubsTable: FC<Props> = ({ data, status }) => {
  const intl = useIntl();

  const columns: Column<CreditEvaluationIncomeSource>[] = [
    {
      id: "#",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.ID",
      }),

      Cell: ({ row }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{"#" + (row.index + 1)}</>
      ),
    },

    {
      accessor: "date",
      Header: "Pay Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{dateFormatter(value)}</>
      ),
    },
    {
      accessor: "amount",
      Header: "Amount",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "ytd",
      Header: "YTD",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "averageAnnual",
      Header: "Avg. Annual",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "numberOfPeriodsToDate",
      Header: "# of Periods to Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{simpleNumberFormatter(value)}</>
      ),
    },
    {
      accessor: "avgPerPeriod",
      Header: "Avg. Per Period",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "averageAnnual2",
      Header: "Avg. Annual 2",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "numberOfPeriodsRemaining",
      Header: "# of Periods Remaining",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{simpleNumberFormatter(value)}</>
      ),
    },
    {
      accessor: "amountOfPayRemaining",
      Header: "$ of Pay Remaining",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "endOfYearExpectedIncome",
      Header: "End of Year Expected Income",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
  ];

  return (
    <Paper>
      <Table data={data} columns={columns} status={status} manualPagination />
    </Paper>
  );
};

export default IncomesPaystubsTable;
