import { Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { CreditEvaluationIncomeSource } from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { percentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

interface Props {
  data: CreditEvaluationIncomeSource[];
  status: QueryStatus;
}
const IncomesSelfEmploymentTable: FC<Props> = ({ data, status }) => {
  const intl = useIntl();

  const columns: Column<CreditEvaluationIncomeSource>[] = [
    {
      id: "#",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.ID",
      }),

      Cell: ({ row }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{"#" + (row.index + 1)}</>
      ),
    },

    {
      accessor: "date",
      Header: "Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{dateFormatter(value)}</>
      ),
    },
    {
      accessor: "grossRevenue",
      Header: "Gross Revenue",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "netProfit",
      Header: "Net Profit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "percentageOfProfit",
      Header: "Percentage Of Profit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{percentageFormatter(value, 100)}</>
      ),
    },
    {
      accessor: "averageMonthlyGrossRevenue",
      Header: "Avg. Monthly Gross Revenue",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "yearOverYearGrossGrowth",
      Header: "Year over Year Growth",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{percentageFormatter(value, 100)}</>
      ),
    },
    {
      accessor: "averageMonthlyNetProfit",
      Header: "Avg. Monthly Net Profit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "yearOverYearNetGrowth",
      Header: "Year over Year Growth",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{percentageFormatter(value || undefined, 100)}</>
      ),
    },
    {
      accessor: "annualWages",
      Header: "Annual Wages",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "mothlyWage",
      Header: "Monthly Wage",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
  ];

  return (
    <Paper>
      <Table data={data} columns={columns} status={status} manualPagination />
    </Paper>
  );
};

export default IncomesSelfEmploymentTable;
