import { Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";

import { editCreditEvaluationAffordability } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationAffordabilityEnum } from "api/creditEvaluations/types";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import {
  AffordabilityColors,
  AffordabilityLabel,
} from "containers/creditEvaluations/CreditEvaluationsTable/formatters/AffordabilityFormatter";

interface ICreditEvaluationAffordabilityForm {
  initialValues: {
    _id: string;
    affordability: CreditEvaluationAffordabilityEnum | undefined;
  };
}

const CreditEvaluationAffordabilityForm: FC<
  ICreditEvaluationAffordabilityForm
> = ({ initialValues }) => {
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const { mutateAsync: editLoanAffordabilitiesRate, isLoading } = useMutation(
    async (value: string) => {
      const res = await editCreditEvaluationAffordability(
        initialValues._id,
        value,
      );

      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(initialValues._id),
      );
      return res.data;
    },
  );

  const { values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <FormikAutocomplete
          label="Affordability"
          name="affordability"
          onChange={(e, option) => {
            setFieldValue("affordability", option?.value);
            editLoanAffordabilitiesRate(
              option?.value || CreditEvaluationAffordabilityEnum.PENDING_EVAL,
            );
          }}
          loading={isLoading}
          options={Object.values(CreditEvaluationAffordabilityEnum).map(
            (value) => {
              return {
                value,
                // @ts-expect-error
                label: AffordabilityLabel[value],
              };
            },
          )}
          renderOption={(props, option) => {
            return (
              // @ts-expect-error
              <Box {...props} color={AffordabilityColors[option.value]}>
                {option.label}
              </Box>
            );
          }}
          inputProps={{
            sx: {
              input: {
                color:
                  values.affordability &&
                  // @ts-expect-error
                  AffordabilityColors[
                    values.affordability as unknown as CreditEvaluationAffordabilityEnum
                  ],
              },
            },
          }}
        />
      </FormikForm>
    </FormikProvider>
  );
};

export default CreditEvaluationAffordabilityForm;
