import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

import { getFile } from "api/file";
import { fileKeys } from "api/file/queries";

const File = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { data: file } = useQuery(
    fileKeys.details(fileId),
    async () => {
      const { data: res } = await getFile(fileId);

      return res;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Box sx={{ height: "100%" }}>
      {fileId.includes("html") && file && (
        <iframe
          title="file"
          srcDoc={file}
          frameBorder="none"
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {fileId.includes("pdf") && file && (
        <iframe
          title="file"
          src={window.URL.createObjectURL(
            new Blob([file], { type: "application/pdf" }),
          )}
          frameBorder="none"
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </Box>
  );
};

export default File;
