import { LoadingButton } from "@mui/lab";
import { Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC, useMemo } from "react";

import { editCreditEvaluationDebt } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationDebtDetails } from "api/creditEvaluations/types";
import FormikNumberField from "components/forms/FormikNumberField";
import { hasPermissions } from "components/stores/UserStore";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

export type DebtPmtFormValues = {
  deferredStudentLoans: number | undefined;
  rentPayment: number | undefined;
  mortgagePayment: number | undefined;
};

interface IDebtPmtTable {
  debtDetails: CreditEvaluationDebtDetails | undefined;
  initialValues: DebtPmtFormValues;
  creditEvaluationId: string;
}

const DebtPmtTable: FC<IDebtPmtTable> = ({
  debtDetails,
  initialValues,
  creditEvaluationId,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editDebt, status } = useMutation(async (data: any) => {
    const res = await editCreditEvaluationDebt(creditEvaluationId, data);
    await queryClient.invalidateQueries(
      creditEvaluationsKeys.details(creditEvaluationId),
    );

    return res.data;
  });

  const handleSubmit = async (values: DebtPmtFormValues) => {
    const mappedValues: any = {
      ...values,
    };

    await editDebt(mappedValues);
  };

  const deferredStudentLoansModified = useMemo(() => {
    return !(
      debtDetails?.deferredStudentLoans.toFixed(2) ===
      debtDetails?.calculatedDeferredStudentLoans.toFixed(2)
    );
  }, [debtDetails]);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <Paper sx={{ mt: 3 }}>
          <Stack
            sx={{
              py: 1.5,
              px: 2,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Debt PMT</Typography>
            <Typography variant="body2">
              {priceFormatter(debtDetails?.debtPayment)}
            </Typography>
          </Stack>
          <Tooltip
            title={
              deferredStudentLoansModified
                ? `Original amount: ${priceFormatter(
                    debtDetails?.calculatedDeferredStudentLoans,
                  )}`
                : ""
            }
            placement="right"
          >
            <Stack
              sx={{
                py: 0.5,
                px: 2,
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                backgroundColor: deferredStudentLoansModified
                  ? "#C8A2C8"
                  : "transparent",
              }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2">Deferred Student Loans</Typography>
              <FormikNumberField
                name="deferredStudentLoans"
                label="Deferred Student Loans"
                InputProps={{
                  inputProps: {
                    style: { textAlign: "right" },
                  },
                }}
                prefix="$"
                sx={{
                  width: "200px",
                  mt: 0.5,
                }}
                disabled={!hasPermissions("update:credit-evaluations")}
              />
            </Stack>
          </Tooltip>
          <Stack
            sx={{
              py: 0.5,
              px: 2,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Rent PMT</Typography>
            <FormikNumberField
              name="rentPayment"
              label="Rent PMT"
              InputProps={{
                inputProps: {
                  style: { textAlign: "right" },
                },
              }}
              prefix="$"
              sx={{ width: "200px", mt: 0.5 }}
              disabled={!hasPermissions("update:credit-evaluations")}
            />
          </Stack>
          <Stack
            sx={{
              py: 1.5,
              px: 2,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Total Debt PMT</Typography>
            <Typography variant="body2">
              {priceFormatter(debtDetails?.totalDebtPayment)}
            </Typography>
          </Stack>
          <Stack
            sx={{
              py: 1.5,
              px: 2,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Spouse Income</Typography>
            <Typography variant="body2">
              {priceFormatter(debtDetails?.spouseIncome)}
            </Typography>
          </Stack>
          <Stack
            sx={{
              py: 1.5,
              px: 2,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Spousal Debt</Typography>
            <Typography variant="body2">
              {priceFormatter(debtDetails?.spousalDebt)}
            </Typography>
          </Stack>
          <Stack
            sx={{
              py: 1.5,
              px: 2,
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Total PMT</Typography>
            <Typography variant="body2">
              {priceFormatter(debtDetails?.totalPayment)}
            </Typography>
          </Stack>
        </Paper>
        <Paper sx={{ mt: 2 }}>
          <Stack
            sx={{
              py: 0.5,
              px: 2,
            }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">Mortgage Amount</Typography>
            <FormikNumberField
              name="mortgagePayment"
              label="Mortgage Amount"
              InputProps={{
                inputProps: {
                  style: { textAlign: "right" },
                },
              }}
              disabled={!hasPermissions("update:credit-evaluations")}
              prefix="$"
              sx={{ width: "200px", mt: 0.5 }}
            />
          </Stack>
        </Paper>
        {hasPermissions("update:credit-evaluations") && (
          <LoadingButton
            type="submit"
            sx={{ mt: 2, ml: "auto" }}
            variant="contained"
            loading={status === "loading"}
          >
            Save
          </LoadingButton>
        )}
      </FormikForm>
    </Formik>
  );
};

export default DebtPmtTable;
