import request, { APICall } from "../request";

export const getFile = (file: string): APICall<any> =>
  request({
    baseURL: process.env.REACT_APP_API_URL?.replace("api", "uploads"),
    url: `/${file}`,
    method: "GET",
    responseType: file.includes("pdf") ? "blob" : "text",
    headers: {
      "Origin-Host": "https://app.loanly.ai",
    },
  });
