import { Box, Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { CreditEvaluationLoan } from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { percentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

interface ILoansTable {
  data: CreditEvaluationLoan[];
  status: QueryStatus;
}

const LoansTable: FC<ILoansTable> = ({ data, status }) => {
  const intl = useIntl();

  const columns: Column<CreditEvaluationLoan>[] = [
    {
      accessor: "status",
      Header: "Status",
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) => (
        <Box textTransform="capitalize">{value}</Box>
      ),
    },
    {
      accessor: "creditor",
      Header: intl.formatMessage({ id: "GLOBAL.CREDITOR" }),
    },

    {
      accessor: "balance",
      Header: intl.formatMessage({ id: "GLOBAL.BALANCE" }),
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) =>
        priceFormatter(value),
    },
    {
      accessor: "payment",
      Header: intl.formatMessage({ id: "GLOBAL.PAYMENT" }),
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) =>
        priceFormatter(value),
    },
    {
      accessor: "hpb",
      Header: "Starting Balance/HPB",
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) =>
        priceFormatter(value),
    },
    {
      accessor: "limit",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.LOANS.TABLE.LIMIT",
      }),
    },
    {
      accessor: "opened",
      Header: intl.formatMessage({ id: "GLOBAL.OPENED" }),
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) =>
        dateFormatter(value),
    },
    {
      accessor: "accountType",
      Header: intl.formatMessage({ id: "GLOBAL.ACCOUNT_TYPE" }),
    },
    {
      accessor: "debitToCreditRatio",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.LOANS.TABLE.DEBT_TO_CREDIT_RATIO",
      }),
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) =>
        percentageFormatter(value * 100),
    },
    {
      accessor: "typeDetail",
      Header: "Type Detail",
    },

    {
      accessor: "reportDate",
      Header: intl.formatMessage({ id: "GLOBAL.REPORT_DATE" }),
      Cell: ({ value }: CellProps<CreditEvaluationLoan>) =>
        dateFormatter(value),
    },
  ];

  return (
    <Paper>
      <Table
        data={data}
        columns={columns}
        status={status}
        initialState={{
          sortBy: [
            {
              id: "reportDate",
              desc: false,
            },
          ],
        }}
        getRowProps={(row) => {
          let backgroundColor;

          if (row.original.status === "closed") {
            if (row.original.balance > 0) {
              backgroundColor = "#fffbab";
            } else {
              backgroundColor = "#f5f5f5";
            }
          } else if (row.original.accountType === "Joint Account") {
            backgroundColor = "#bedfff";
          }

          return {
            sx: { backgroundColor },
          };
        }}
        manualPagination
      />
    </Paper>
  );
};

export default LoansTable;
