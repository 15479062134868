import { Box, Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps } from "react-table";

import { CreditEvaluationTradeline } from "api/creditEvaluations/types";
import Table, { TableColumn } from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { percentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { calcaulatePaydown } from "utils/creditEvaluation/calculatePaydown";

interface Props {
  data: CreditEvaluationTradeline[];
  status: QueryStatus;
}

const TradelinesTable: FC<Props> = ({ data, status }) => {
  const intl = useIntl();

  const columns: TableColumn<CreditEvaluationTradeline>[] = [
    {
      accessor: "status",
      Header: "Status",
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) => (
        <Box textTransform="capitalize">{value}</Box>
      ),
    },
    {
      accessor: "creditor",
      Header: intl.formatMessage({ id: "GLOBAL.CREDITOR" }),
    },
    {
      accessor: "balance",
      Header: intl.formatMessage({ id: "GLOBAL.BALANCE" }),
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(value),
    },
    {
      accessor: "payment",
      Header: intl.formatMessage({ id: "GLOBAL.PAYMENT" }),
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(value),
    },
    {
      accessor: "hpb",
      Header: "HPB",
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(value),
    },
    {
      accessor: "creditLimit",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.TRADELINES.TABLE.CREDIT_LIMIT",
      }),
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(value),
    },
    {
      accessor: "opened",
      Header: intl.formatMessage({ id: "GLOBAL.OPENED" }),
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        dateFormatter(value),
    },
    {
      accessor: "accountType",
      Header: intl.formatMessage({ id: "GLOBAL.ACCOUNT_TYPE" }),
    },
    {
      accessor: "utilizationRate",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.TRADELINES.TABLE.UTILIZATION_RATE",
      }),
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        percentageFormatter(value * 100),
    },
    {
      id: "paydown50",
      Header: "Paydown 50%",
      Cell: ({ row }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(calcaulatePaydown(50, row.original)),
      display: false,
    },
    {
      id: "paydown60",
      Header: "Paydown 60%",
      Cell: ({ row }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(calcaulatePaydown(60, row.original)),
    },
    {
      id: "paydown75",
      Header: "Paydown 75%",
      Cell: ({ row }: CellProps<CreditEvaluationTradeline>) =>
        priceFormatter(calcaulatePaydown(75, row.original)),
      display: false,
    },
    {
      accessor: "typeDetail",
      Header: "Type Detail",
    },
    {
      accessor: "reportDate",
      Header: intl.formatMessage({ id: "GLOBAL.REPORT_DATE" }),
      Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
        dateFormatter(value),
    },
  ];

  return (
    <>
      <Paper>
        <Table
          data={data}
          columns={columns}
          status={status}
          initialState={{
            sortBy: [
              {
                id: "reportDate",
                desc: false,
              },
            ],
          }}
          getRowProps={(row) => {
            let backgroundColor;

            if (row.original.status === "closed") {
              if (row.original.balance > 0) {
                backgroundColor = "#fffbab";
              } else {
                backgroundColor = "#f5f5f5";
              }
            } else if (row.original.accountType === "Joint Account") {
              backgroundColor = "#bedfff";
            } else if (row.original.utilizationRate >= 0.5) {
              backgroundColor = "#edc3cd";
            }

            return {
              sx: { backgroundColor },
            };
          }}
          additionalRows={[
            {
              cells: [
                { value: <b>Total:</b> },
                { value: null },
                {
                  value: (
                    <b>
                      {priceFormatter(
                        data.reduce(
                          (prev: number, tradeline) => prev + tradeline.balance,
                          0,
                        ),
                      )}
                    </b>
                  ),
                },
                {
                  value: (
                    <b>
                      {priceFormatter(
                        data.reduce(
                          (prev: number, tradeline) => prev + tradeline.payment,
                          0,
                        ),
                      )}
                    </b>
                  ),
                },
                { value: null },
                {
                  value: (
                    <b>
                      {priceFormatter(
                        data.reduce(
                          (prev: number, tradeline) =>
                            prev + tradeline.creditLimit,
                          0,
                        ),
                      )}
                    </b>
                  ),
                },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
              ],
            },
          ]}
          manualPagination
        />
      </Paper>
    </>
  );
};

export default TradelinesTable;
