import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  // Typography
} from "@mui/material";
import React, { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouteLink } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";

import { LoanApplicationsType } from "api/loanApplications/types";
import { getLoanPackages } from "api/loanPackages";
import { loanPackagesKeys } from "api/loanPackages/queries";
import { LoanPackagesType } from "api/loanPackages/types";
import Table from "components/Table";
import { precisePercentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import LoanApplicationsTable from "./SubTables/LoanApplicationsTable";
import { useLoanPackagesFilter } from "./useLoanPackagesFilter";

const LoanPackagesTable: FC = () => {
  // const intl = useIntl();
  const { filters, searchField } = useLoanPackagesFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    loanPackagesKeys.list(),
    getLoanPackages,
    filters,
  );

  const columns: Column<LoanPackagesType>[] = [
    {
      accessor: "name",
      Header: "Deal Name",
      Aggregated: ({ row, value }: CellProps<LoanPackagesType>) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            {...row.getToggleRowExpandedProps()}
          >
            <>
              {row.original.loanApplications?.length}
              {row.isExpanded ? (
                <KeyboardArrowDown sx={{ mr: 1 }} />
              ) : (
                <KeyboardArrowRight sx={{ mr: 1 }} />
              )}
              {value}
            </>
          </Stack>
        );
      },
      Cell: () => null,
    },
    {
      accessor: "loanAmount",
      Header: "Loan Amount",
      Aggregated: ({ value }: CellProps<LoanPackagesType>) =>
        priceFormatter(value),
      Cell: () => null,
    },
    {
      accessor: "monthlyPayment",
      Header: "Monthly Payment",
      Aggregated: ({ value }: CellProps<LoanPackagesType>) =>
        priceFormatter(value),
      Cell: () => null,
    },
    {
      accessor: "term",
      Header: "Term",
      Cell: () => null,
    },
    {
      accessor: "interestRate",
      Header: "Interest Rate",
      Aggregated: ({ value }: CellProps<LoanPackagesType>) =>
        precisePercentageFormatter(value),
      Cell: () => null,
    },
    {
      accessor: "originationFee",
      Header: "Origination Fee",
      Aggregated: ({ value }: CellProps<LoanPackagesType>) =>
        priceFormatter(value),
      Cell: () => null,
    },
    {
      accessor: "apr",
      Header: "APR",
      Aggregated: ({ value }: CellProps<LoanPackagesType>) =>
        precisePercentageFormatter(value),
      Cell: () => null,
    },
    {
      id: "reports",
      Header: "Reports",
      Aggregated: ({ row }: CellProps<LoanPackagesType>) => (
        <Stack direction="column" spacing={0.5}>
          <Link
            component={RouteLink}
            to={`/credit-evaluations/${row.original.creditEvaluation?._id}`}
          >
            Credit Evaluation
          </Link>
          <Link
            component={RouteLink}
            to={`/customers/${row.original.customer?._id}`}
          >
            Customer Details
          </Link>

          <Link
            component="a"
            href={`https://app.hubspot.com/contacts/3992366/contact/${row.original.customer?.hubspotId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Hubspot Contact Details
          </Link>
        </Stack>
      ),
      Cell: () => null,
    },
  ];

  const getSubRows = useCallback(
    (loanPackageRow: any) =>
      loanPackageRow.loanApplications?.length
        ? [loanPackageRow.loanApplications]
        : [],
    [],
  );

  return (
    <>
      <Grid container sx={{ py: 2 }} spacing={2}>
        <Grid item xs={5} lg={3}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="LOAN_PACKAGES.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
        {/* <Grid item xs={12}></Grid> */}
      </Grid>

      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
          getSubRows={getSubRows}
          subComponent={(row: Row<LoanApplicationsType[]>) => {
            return (
              <Box component="td" colSpan={columns.length + 1} sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Loan Applications
                </Typography>
                <LoanApplicationsTable data={row.original} status={status} />
              </Box>
            );
          }}
        />
      </Paper>
    </>
  );
};

export default LoanPackagesTable;
