import { formatNumber } from "utils/formatNumber";

export const priceFormatter = (value: number | null | undefined) => {
  if (value !== null && value !== undefined && !Number.isNaN(value)) {
    return formatNumber(value, {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return "-";
};
