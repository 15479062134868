import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

// COMPONENTS:

import { addCustomer as addCustomerAPI } from "api/customers";
import { customersKeys } from "api/customers/queries";
import { NewCustomerPayloadType } from "api/customers/types";

import CustomerForm, { CustomerFormValues } from "./CustomerForm";

// LOGIC:

// TYPES:

interface NewCustomerModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const NewCustomerModal = ({ isOpen, handleClose }: NewCustomerModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addCustomer, status } = useMutation(
    async (data: NewCustomerPayloadType) => {
      const res = await addCustomerAPI(data);
      await queryClient.invalidateQueries(customersKeys.list());
      return res.data;
    },
  );

  const handleSubmit = async (values: CustomerFormValues) => {
    await addCustomer(values, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="CUSTOMERS.FORM.NEW_CUSTOMER.HEADER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <CustomerForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default NewCustomerModal;
