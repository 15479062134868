import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
// import dayjs from "dayjs";
import dayjs from "dayjs";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { getHubspotLenders } from "api/hubspot";
import { hubspotKeys } from "api/hubspot/queries";
import { HubspotLenderCreditBureauEnum } from "api/hubspot/types";
import {
  LoanApplicationAccountType,
  LoanApplicationCreditInquiry,
  LoanApplicationStatus,
} from "api/loanApplications/types";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikAutocompleteMultiple from "components/forms/FormikAutocompleteMultiple";
import FormikDatePicker from "components/forms/FormikDatePicker";
import FormikNumberField from "components/forms/FormikNumberField";

import { loanAccountTypeFormatter } from "../formatters/loanAccountTypeFormatter";
import { loanStatusFormatter } from "../formatters/loanStatusFormatter.tsx";

export type LoanApplicationFormValues = {
  _id: string;
  lenderId: string | null;
  loanAmount: number | null;
  monthlyPayment: number | null;
  term: number | null;
  creditInquiry: string[];
  applicationDate: Date | string | null;
  status: string | null;
  accountType: string;
  interestRate: number | null;
  originationFee: number | null;
  reasonCode: string | null;
};

interface ILoanApplicationForm {
  handleSubmit: (values: LoanApplicationFormValues) => void;
  handleClose: () => void;
  initialValues?: LoanApplicationFormValues;
  submitStatus?: MutationStatus;
  edit?: boolean;
}

const creditInquiryOptions = [
  { label: "Experian", value: LoanApplicationCreditInquiry.EXPERIAN },
  { label: "Equifax", value: LoanApplicationCreditInquiry.EQUIFAX },
  {
    label: "TransUnion",
    value: LoanApplicationCreditInquiry.TRANSUNION,
  },
  { label: "Tri-Merge", value: LoanApplicationCreditInquiry.TRI_MERGE },
  { label: "Soft Pull", value: LoanApplicationCreditInquiry.SOFT_PULL },
];

const loanApplicationStatusOptions = Object.values(LoanApplicationStatus).map(
  (value) => ({
    value,
    label: loanStatusFormatter(value as LoanApplicationStatus),
  }),
);

const accountTypeOptions = [
  LoanApplicationAccountType.UNSECURED_LOAN_INDIVIDUAL,
  LoanApplicationAccountType.UNSECURED_LOAN_JOINT,
  LoanApplicationAccountType.BUSINESS_LOAN_PERSONALLY_GUARANTEED,
].map((value) => ({
  value,
  label: loanAccountTypeFormatter(value),
}));

const reasonCodeOptions = [
  "Home Improvement",
  "Large Purchase",
  "Debt Consolidation",
  "Business Acquisition",
  "Business Start Up",
  "Business",
].map((value) => ({
  value,
  label: value,
}));

const now = dayjs().toString();

const LoanApplicationForm: FC<ILoanApplicationForm> = ({
  handleClose,
  handleSubmit,
  initialValues = {
    _id: "",
    lenderId: null,
    loanAmount: null,
    monthlyPayment: null,
    term: null,
    creditInquiry: [],
    // applicationDate: null,
    applicationDate: now,
    status: LoanApplicationStatus.APPLICATION_PENDING_SUBMISSION,
    accountType: "",
    interestRate: null,
    originationFee: null,
    reasonCode: null,
  },
  submitStatus,
}) => {
  const schema = yup.object({
    lenderId: yup.string().required().nullable(),
    loanAmount: yup
      .number()
      // .required()
      .nullable(),
    monthlyPayment: yup
      .number()
      // .required()
      .nullable(),
    term: yup
      .number()
      // .required()
      .nullable(),
    creditInquiry: yup
      .array()
      // .required()
      .nullable(),
    applicationDate: yup.date().required().nullable(),
    status: yup.string().required().nullable(),
    accountType: yup.string().required().nullable(),
    interestRate: yup
      .number()
      // .required()
      .nullable(),
    // originationFee: yup.number(),
    reasonCode: yup.string().required().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { setFieldValue } = formik;

  const { data: lenders, isLoading: areLendersLoading } = useQuery(
    hubspotKeys.listLenders(),
    async () => {
      const { data: res } = await getHubspotLenders();
      return res.data;
    },
  );

  const lendersOptions = useMemo(() => {
    if (lenders?.length) {
      return lenders.map((lender) => ({
        value: lender.id,
        label: lender.lender_name,
      }));
    }

    return [];
  }, [lenders]);

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikAutocomplete
            options={lendersOptions}
            name="lenderId"
            label="Lender"
            loading={areLendersLoading}
            onChange={async (e, target) => {
              const lenderId = target?.value;

              await setFieldValue("lenderId", lenderId);
              await setFieldValue("originationFee", "");
              await setFieldValue("creditInquiry", []);

              const lender = lenders?.find(
                (singleLender) => singleLender.id === lenderId,
              );
              if (lender?.origination_fee) {
                await setFieldValue(
                  "originationFee",
                  parseFloat(lender?.origination_fee),
                );
              }
              if (lender?.credit_inquiry) {
                const creditBureaus =
                  lender.credit_inquiry
                    .split(";")
                    .map(
                      (creditBureau) =>
                        HubspotLenderCreditBureauEnum[creditBureau as any],
                    ) ?? [];
                setFieldValue("creditInquiry", creditBureaus);
              }
            }}
          />
          <FormikNumberField name="loanAmount" label="Loan Amount" prefix="$" />
          <FormikNumberField
            name="monthlyPayment"
            label="Monthly Payment"
            prefix="$"
          />
          <FormikNumberField name="term" label="Term" />
          <FormikAutocompleteMultiple
            name="creditInquiry"
            label="Credit Inquiry"
            options={creditInquiryOptions}
          />
          {/* <Autocomplete
            multiple
            options={creditInquiryOptions.filter(el => {
              let notFound = true;
              if (selectedCreditInquiries.find(el2 => el2.value === el.value))
                notFound = false;
              return notFound;
            })}
            value={selectedCreditInquiries}
            filterSelectedOptions
            disableClearable
            renderTags={value =>
              value.map(option => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: 0.5
                  }}
                >
                  <Chip
                    label={option.label}
                    onDelete={() => unlinkCreditInquiry(option.value)}
                  />
                </Box>
              ))
            }
            onChange={(_e, value) => handleCreditInquirySelect(value)}
            renderInput={params => {
              return <TextField {...params} label={"Credit Inquiry"} />;
            }}
          /> */}
          <FormikDatePicker
            name="applicationDate"
            label="Application Date"
            timezone="UTC"
          />
          <FormikAutocomplete
            options={loanApplicationStatusOptions}
            name="status"
            label="Status"
          />
          {/* select */}
          <FormikAutocomplete
            options={accountTypeOptions}
            name="accountType"
            label="Account Type"
          />
          <FormikNumberField
            name="interestRate"
            label="Interest Rate"
            suffix="%"
          />
          <FormikNumberField
            name="originationFee"
            label="Origination Fee Amount"
            prefix="$"
          />
          <FormikAutocomplete
            options={reasonCodeOptions}
            name="reasonCode"
            label="Reason Code"
          />
        </DialogContent>
        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default LoanApplicationForm;
