import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { editCreditEvaluationIncome } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationIncome } from "api/creditEvaluations/types";

import IncomeForm, { IncomeFormValues } from "./IncomeForm";

interface EditIncomeModalProps {
  creditEvaluationId: string;
  isOpen: boolean;
  handleClose: () => void;
  context: CreditEvaluationIncome | undefined;
}

const EditIncomeModal: FC<EditIncomeModalProps> = ({
  creditEvaluationId,
  isOpen,
  handleClose,
  context,
}) => {
  const queryClient = useQueryClient();

  const initialValues: IncomeFormValues = {
    _id: context?._id || "",
    type: context?.type || "",
    period: context?.period || "",
    incomes:
      context?.incomeSources.map((incomeSource) => ({
        date: incomeSource.date || null,
        amount: incomeSource.amount || null,
        ytd: incomeSource.ytd || null,
        grossRevenue: incomeSource.grossRevenue || null,
        netProfit: incomeSource.netProfit || null,
        annualWages: incomeSource.annualWages || null,
        source: incomeSource.source || "",
        monthlyBenefit: incomeSource.monthlyBenefit || null,
      })) || [],
  };

  const { mutateAsync: editIncome, status } = useMutation(async (data: any) => {
    const res = await editCreditEvaluationIncome(
      creditEvaluationId,
      data._id,
      data,
    );
    await queryClient.invalidateQueries(
      creditEvaluationsKeys.details(creditEvaluationId),
    );

    return res.data;
  });

  const handleSubmit = async (values: IncomeFormValues) => {
    const mappedValues: any = {
      ...values,
    };

    await editIncome(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="CREDIT_EVALUATIONS.INCOMES.ADD" />
        </Stack>
      </DialogTitle>
      <Divider />

      <IncomeForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitStatus={status}
        initialValues={initialValues}
      />
    </Dialog>
  );
};

export default EditIncomeModal;
