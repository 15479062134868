import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouteLink } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationsType } from "api/creditEvaluations/types";
import { deleteCustomerSpouse as deleteCustomerSpouseAPI } from "api/customers";
import { ReactComponent as HubspotSVG } from "assets/icons/hubspot.svg";
import { hasPermissions } from "components/stores/UserStore";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import CustomerField from "containers/customers/details/components/CustomerField";
import dayjs from "utils/dayjs";
import { getFileLink } from "utils/getFile";
import { useModal } from "utils/hooks/useModal";

import AddSpouseModal from "../components/AddSpouse/AddSpouseModal";
import CreditEvaluationAffordabilityForm from "./CreditEvaluationAffordabilityForm";
import CreditEvaluationCustomer from "./CreditEvaluationCustomer";
import EditHubspotDealIdModal from "./EditHubspotDealId/EditHubspotDealIdModal";

interface CreditEvaluationOverviewProps {
  creditEvaluation?: CreditEvaluationsType;
  isLoading: boolean;
}

export const averageAgeFormatter = (months: number | undefined) => {
  if (!months) return "-";

  const years = Math.floor(months / 12);
  months = Math.floor(months % 12);

  if (years) {
    return `${years} Year${years !== 1 ? "s" : ""}, ${months} Month${
      months !== 1 ? "s" : ""
    }`;
  } else return `${months} Month${months !== 1 ? "s" : ""}`;
};

export const fromNowMonths = (date: Date | undefined) => {
  if (!date) return "-";

  let months = dayjs().diff(dayjs(date), "months");

  const years = Math.floor(months / 12);
  months = Math.floor(months % 12);

  if (years) {
    return `${years} Year${years !== 1 ? "s" : ""}, ${months} Month${
      months !== 1 ? "s" : ""
    }`;
  } else return `${months} Month${months !== 1 ? "s" : ""}`;
};

const CreditEvaluationOverview: FC<CreditEvaluationOverviewProps> = ({
  creditEvaluation,
  isLoading,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteCustomerSpouse } = useMutation(async () => {
    const res = await deleteCustomerSpouseAPI(
      creditEvaluation?.customer?._id ?? "",
    );
    await queryClient.invalidateQueries(
      creditEvaluationsKeys.details(creditEvaluation?._id ?? ""),
    );
    return res.data;
  });

  const {
    isOpen: isOpenAddSpouse,
    handleClose: handleCloseAddSpouse,
    handleOpen: handleOpenAddSpouse,
  } = useModal<CreditEvaluationsType>();

  const {
    isOpen: isOpenEditHubspotDealId,
    handleClose: handleCloseEditHubspotDealId,
    handleOpen: handleOpenEditHubspotDealId,
  } = useModal<CreditEvaluationsType>();

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Grid container spacing={2} sx={{ p: 3.5 }}>
          <Grid item sx={{ textAlign: "left" }} xs={12} lg={4}>
            <CreditEvaluationCustomer customer={creditEvaluation?.customer} />
          </Grid>
          <Grid item sx={{ textAlign: "left" }} xs={12} lg={4}>
            <CreditEvaluationAffordabilityForm
              initialValues={{
                _id: creditEvaluation?._id || "",
                affordability: creditEvaluation?.affordability,
              }}
            />
          </Grid>
          <Grid item sx={{ textAlign: "right" }} xs={2} lg={2}>
            {creditEvaluation?.customer?.hubspotId && (
              <Link
                href={`https://app.hubspot.com/contacts/3992366/contact/${creditEvaluation.customer.hubspotId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HubspotSVG style={{ height: "50px" }} />
              </Link>
            )}
          </Grid>

          <Grid item sx={{ textAlign: "right" }} xs={10} lg={1.5}>
            <Link
              component={RouteLink}
              to={getFileLink(creditEvaluation?.html.replace("html", "pdf"))}
              sx={{ display: "block" }}
            >
              <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.PDF_REPORT" />
            </Link>
            <Link
              component={RouteLink}
              to={getFileLink(creditEvaluation?.html)}
            >
              <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.HTML_REPORT" />
            </Link>
          </Grid>

          <Grid item sx={{ textAlign: "left" }} xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label={
                <FormattedMessage id="CREDIT_EVALUATIONS.EVALUATION_OVERVIEW.LAST_REPORT_DATE" />
              }
              value={dateFormatter(creditEvaluation?.reportDate)}
            />
            <CustomerField
              isLoading={isLoading}
              label={
                <FormattedMessage id="CREDIT_EVALUATIONS.EVALUATION_OVERVIEW.AVERAGE_AGE" />
              }
              value={averageAgeFormatter(
                creditEvaluation?.averageMonthsOfOpenRevolvingCredit,
              )}
            />
            <Stack
              sx={{
                py: creditEvaluation?.customer?.spouse ? 1.5 : 1,
                pl: 2,
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2" color="#000" fontWeight="bold">
                <FormattedMessage id="CREDIT_EVALUATIONS.SPOUSE" />
              </Typography>

              {isLoading ? (
                <Skeleton height={20} width={120} />
              ) : creditEvaluation?.customer?.spouse ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Link
                    component={RouterLink}
                    to={`/customers/${creditEvaluation?.customer?.spouse._id}`}
                    sx={{ fontSize: 14 }}
                  >
                    {creditEvaluation?.customer?.spouse.firstName}{" "}
                    {creditEvaluation?.customer?.spouse.lastName}
                  </Link>

                  {hasPermissions("update:customers") && (
                    <IconButton
                      color="error"
                      onClick={() => deleteCustomerSpouse()}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Stack>
              ) : hasPermissions("update:customers") ? (
                <Button
                  onClick={() => handleOpenAddSpouse()}
                  variant="contained"
                >
                  <FormattedMessage id="CREDIT_EVALUATIONS.ADD_SPOUSE" />
                </Button>
              ) : null}
            </Stack>
            <Stack
              sx={{
                py: creditEvaluation?.customer?.spouse ? 1.5 : 1,
                pl: 2,
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2" color="#000" fontWeight="bold">
                {/* <FormattedMessage id="CREDIT_EVALUATIONS.SPOUSE" /> */}
                Hubspot Deal ID
              </Typography>

              {isLoading ? (
                <Skeleton height={20} width={120} />
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Link
                    href={`https://app.hubspot.com/contacts/3992366/record/0-3/${creditEvaluation?.hubspotDealId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontSize: 14 }}
                  >
                    {creditEvaluation?.hubspotDealId}
                  </Link>

                  <Button
                    onClick={() => handleOpenEditHubspotDealId()}
                    variant="contained"
                  >
                    Edit
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item sx={{ textAlign: "left" }} xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label={
                <FormattedMessage id="CREDIT_EVALUATIONS.EVALUATION_OVERVIEW.FIRST_CREDIT_ACCOUNT" />
              }
              value={creditEvaluation?.firstCreditAccount}
            />
          </Grid>
          <Grid item sx={{ textAlign: "left" }} xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label={
                <FormattedMessage id="CREDIT_EVALUATIONS.EVALUATION_OVERVIEW.AGE_OF_FILE" />
              }
              value={
                creditEvaluation?.ageOfFile
                  ? dayjs(creditEvaluation.ageOfFile).fromNow()
                  : "-"
              }
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <CustomerField
              isLoading={isLoading}
              label={"Late Payments"}
              value={
                creditEvaluation?.latePayments?.map((latePayment) => {
                  return (
                    <Box>
                      {latePayment.paymentPattern.length
                        ? latePayment.paymentPattern.join(", ")
                        : fromNowMonths(latePayment.reportDate)}{" "}
                      - {latePayment.creditor} ({latePayment.rating30}x30,
                      {latePayment.rating60}x60,{latePayment.rating90}x90)
                    </Box>
                  );
                }) ?? "-"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerField
              isLoading={isLoading}
              label={"Charge-Offs"}
              value={
                creditEvaluation?.chargeOffs?.map((chargeOff) => {
                  return (
                    <Box>
                      {dateFormatter(chargeOff.reportDate)} -{" "}
                      {chargeOff.creditor} ({priceFormatter(chargeOff.pastdue)})
                    </Box>
                  );
                }) ?? "-"
              }
            />
          </Grid>
        </Grid>
      </Paper>
      {creditEvaluation && (
        <AddSpouseModal
          creditEvaluationId={creditEvaluation._id}
          isOpen={isOpenAddSpouse}
          handleClose={handleCloseAddSpouse}
          customerId={creditEvaluation?.customer?._id || ""}
        />
      )}
      {creditEvaluation && (
        <EditHubspotDealIdModal
          creditEvaluationId={creditEvaluation._id}
          hubspotDealId={creditEvaluation.hubspotDealId}
          isOpen={isOpenEditHubspotDealId}
          handleClose={handleCloseEditHubspotDealId}
        />
      )}
    </>
  );
};

export default CreditEvaluationOverview;
