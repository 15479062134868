import {
  CreditEvaluationIncome,
  CreditEvaluationIncomeTypeEnum,
} from "api/creditEvaluations/types";

export const incomeTypeFormatter = (
  value: CreditEvaluationIncome | undefined | null,
) => {
  switch (value?.type) {
    case CreditEvaluationIncomeTypeEnum.PAYSTUB:
      return "W2/Paystub";
    case CreditEvaluationIncomeTypeEnum.SELF_EMPLOYMENT:
      return "Self Employment";
    case CreditEvaluationIncomeTypeEnum.ADDITIONAL_INCOME:
      return (
        "Additional Income - " +
        value.incomeSources
          ?.map((incomeSource) => incomeSource.source)
          .join(", ")
      );
    case CreditEvaluationIncomeTypeEnum.HOUSING_ALLOWANCE:
      return "Housing Allowance";
    default:
      return "-";
  }
};

export const incomeTypeFormatterSummary = (
  value:
    | { type: CreditEvaluationIncomeTypeEnum; source: string | undefined }
    | undefined
    | null,
) => {
  switch (value?.type) {
    case CreditEvaluationIncomeTypeEnum.PAYSTUB:
      return "W2/Paystub";
    case CreditEvaluationIncomeTypeEnum.SELF_EMPLOYMENT:
      return "Self Employment";
    case CreditEvaluationIncomeTypeEnum.ADDITIONAL_INCOME:
      return "Additional Income - " + value.source;
    case CreditEvaluationIncomeTypeEnum.HOUSING_ALLOWANCE:
      return "Housing Allowance";
    default:
      return "-";
  }
};
