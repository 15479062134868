import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Paper, Stack } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { FC, useCallback, useMemo } from "react";
import { CellProps, Column } from "react-table";

import { CreditEvaluationSummaryOfIncomes } from "api/creditEvaluations/types";
import Table from "components/Table";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

import { incomeTypeFormatterSummary } from "./formatters/incomeTypeFormatter";

interface ISummaryOfIncomesTable {
  data: CreditEvaluationSummaryOfIncomes | undefined;
  status: QueryStatus;
}

type SummaryOfIncomesYear = {
  year: number;
  eoyExpected: number;
  type: string;
  children: CreditEvaluationSummaryOfIncomes["incomeSources"];
};

const SummaryOfIncomesTable: FC<ISummaryOfIncomesTable> = ({
  data,
  status,
}) => {
  const columns: Column<SummaryOfIncomesYear>[] = [
    {
      accessor: "year",
      Header: "Year",
      Aggregated: ({ row, value }: CellProps<SummaryOfIncomesYear>) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            {...row.getToggleRowExpandedProps()}
          >
            <>
              {row.original.children?.length}
              {row.isExpanded ? (
                <KeyboardArrowDown sx={{ mr: 1 }} />
              ) : (
                <KeyboardArrowRight sx={{ mr: 1 }} />
              )}
              {value}
            </>
          </Stack>
        );
      },
      Cell: ({ value }) => <>{value}</>,
    },
    {
      accessor: "eoyExpected",
      Header: "EOY/EOY Expected",
      Aggregated: ({ value }: CellProps<SummaryOfIncomesYear>) =>
        priceFormatter(value),
      Cell: ({ value }: CellProps<SummaryOfIncomesYear>) =>
        priceFormatter(value),
    },
    {
      accessor: "type",
      Header: "Type",
      Aggregated: () => <></>,
      Cell: ({
        row,
      }: CellProps<CreditEvaluationSummaryOfIncomes["incomeSources"][0]>) =>
        incomeTypeFormatterSummary({
          type: row.original.type,
          source: row.original.source,
        }),
    },
  ];

  const groupIncomeSourcesByYear = (
    incomeSources: CreditEvaluationSummaryOfIncomes["incomeSources"],
  ): Array<SummaryOfIncomesYear> => {
    let result: Array<SummaryOfIncomesYear> = [];
    let incomeSourcesSorted = incomeSources.sort((a, b) => a.year - b.year);
    incomeSourcesSorted.forEach((incomeSource) => {
      let year = incomeSource.year;
      let foundIndex = result.findIndex((r) => r.year === year);
      if (foundIndex === -1)
        result.push({
          year,
          eoyExpected: incomeSource.eoyExpected,
          children: [incomeSource],
          type: "-",
        });
      else {
        result[foundIndex].eoyExpected += incomeSource.eoyExpected;
        result[foundIndex].children.push(incomeSource);
      }
    });
    return result;
  };

  const tableData = useMemo(() => {
    if (data?.incomeSources) {
      return groupIncomeSourcesByYear(data?.incomeSources);
    }
    return [];
  }, [data?.incomeSources]);

  const getSubRows = useCallback(
    (summaryIncomeRow: any) =>
      summaryIncomeRow.children?.length ? summaryIncomeRow.children : [],
    [],
  );

  return (
    <Paper>
      <Table
        data={tableData}
        columns={columns}
        status={status}
        getSubRows={getSubRows}
        manualPagination
      />
    </Paper>
  );
};

export default SummaryOfIncomesTable;
