import { LoanApplicationAccountType } from "api/loanApplications/types";

export const loanAccountTypeFormatter = (
  value: LoanApplicationAccountType | undefined | null,
) => {
  switch (value) {
    case LoanApplicationAccountType.AUTO_LEASE_INDIVIDUAL:
      return "Auto Lease - Individual";
    case LoanApplicationAccountType.AUTO_LEASE_JOINT:
      return "Auto Lease - Joint";
    case LoanApplicationAccountType.AUTO_LOAN_INDIVIDUAL:
      return "Auto Loan - Individual";
    case LoanApplicationAccountType.AUTO_LOAN_JOINT:
      return "Auto Loan - Joint";
    case LoanApplicationAccountType.CHILD_SUPPORT:
      return "Child Support";
    case LoanApplicationAccountType.BUSINESS_LOAN_PERSONALLY_GUARANTEED:
      return "Business Loan - Personally Guaranteed";
    case LoanApplicationAccountType.HELOAN_INDIVIDUAL:
      return "HELOAN - Individual";
    case LoanApplicationAccountType.HELOAN_JOINT:
      return "HELOAN - Joint";
    case LoanApplicationAccountType.HELOC_INDIVIDUAL:
      return "HELOC - Individual";
    case LoanApplicationAccountType.HELOC_JOINT:
      return "HELOC - Joint";
    case LoanApplicationAccountType.HOME_IMPROVEMENT_LOAN_INDIVIDUAL:
      return "Home Improvement Loan - Individual";
    case LoanApplicationAccountType.HOME_IMPROVEMENT_LOAN_JOINT:
      return "Home Improvement Loan - Joint";
    case LoanApplicationAccountType.INSTALLMENT_SALES_CONTRACT:
      return "Installment Sales Contract";
    case LoanApplicationAccountType.MOBILE_HOME_INDIVIDUAL:
      return "Mobile Home - Individual";
    case LoanApplicationAccountType.MOBILE_HOME_JOINT:
      return "Mobile Home - Joint";
    case LoanApplicationAccountType.MORTGAGE_FIRST_INDIVIDUAL:
      return "Mortgage 1st - Individual";
    case LoanApplicationAccountType.MORTGAGE_FIRST_JOINT:
      return "Mortgage 1st - Joint";
    case LoanApplicationAccountType.MORTGAGE_SECOND_INDIVIDUAL:
      return "Mortgage 2nd - Individual";
    case LoanApplicationAccountType.MORTGAGE_SECOND_JOINT:
      return "Mortgage 2nd - Joint";
    case LoanApplicationAccountType.RECREATIONAL_VEHICLE_INDIVIDUAL:
      return "Recreational Vehicle - Individual";
    case LoanApplicationAccountType.RECREATIONAL_VEHICLE_JOINT:
      return "Recreational Vehicle - Joint";
    case LoanApplicationAccountType.RENTAL_AGREEMENT:
      return "Rental Agreement";
    case LoanApplicationAccountType.SECURED_LOAN_INDIVIDUAL:
      return "Secured Loan - Individual";
    case LoanApplicationAccountType.SECURED_LOAN_JOINT:
      return "Secured Loan - Joint";
    case LoanApplicationAccountType.STUDENT_LOAN_INDIVIDUAL:
      return "Student Loan - Individual";
    case LoanApplicationAccountType.STUDENT_LOAN_JOINT:
      return "Student Loan - Joint";
    case LoanApplicationAccountType.TIME_SHARE_LOAN_INDIVIDUAL:
      return "Time Share Loan - Individual";
    case LoanApplicationAccountType.TIME_SHARE_LOAN_JOINT:
      return "Time Share Loan - Joint";
    case LoanApplicationAccountType.UNSECURED_LOAN_INDIVIDUAL:
      return "Unsecured Loan - Individual";
    case LoanApplicationAccountType.UNSECURED_LOAN_JOINT:
      return "Unsecured Loan - Joint";
    case LoanApplicationAccountType.UTILITY_SELF_REPORTED:
      return "Utility - Self Reported";
    default:
      return "-";
  }
};
